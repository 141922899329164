
import { IonContent, IonPage, IonRow, IonCol, IonGrid, menuController } from '@ionic/vue';
import { Storage } from '@capacitor/storage';
export default {
  name: 'LoginPage',
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonGrid
  },
  data: () => ({
    clientData: {}
  }),
  methods: {
    async getClientData(keyName) {
      const ret = await Storage.get({ key: keyName });
      if (ret.value) {
        this.clientData = JSON.parse(ret.value);
        // console.log(this.clientData); 
        this.$router.replace("/tabs/tabReproductor");
      } else {
        console.log("No hay info de cliente en el storage");
      }
    },
  },
  mounted: function () {
    menuController.close("menustudio");
    menuController.enable(false, "menustudio");
    this.getClientData("clientData");

  }
}
